<template>
  <section>
    <el-form :inline="true" size="mini">
      <el-form-item>
        <el-cascader
          placeholder="所属单位"
          v-model="selOwner"
          :options="customerTree"
          :props="orgProps"
          clearable
          filterable
        />
      </el-form-item>
      <el-form-item>
        <el-cascader
          placeholder="区域"
          v-model="selArea"
          :options="areaTree"
          :props="areaProps"
          clearable
          filterable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getData">查询</el-button>
      </el-form-item>
      <el-form-item>
        <div class="quick_jump_box">
          <router-link v-for="(item, index) in quickJumpMenus" :key="index" :to="item.page">
            <i class="el-icon-message" :style="{
                color: item.color
              }"></i>
            <span>{{ item.text }}</span>
          </router-link>
        </div>
      </el-form-item>
    </el-form>
    <div v-loading="mapLoading">
      <table class="device-info">
        <thead>
        <tr>
          <th rowspan="2">监测点 {{ LocaleNum }}</th>
          <th rowspan="2">监测设备 {{ Dtotal }}</th>
          <th>昨日在线 {{ daily.Dnormal + daily.Dexcess }}</th>
          <th>昨日离线 {{ Dtotal - daily.Dnormal - daily.Dexcess }}</th>
          <th>昨日超标 {{ daily.Dexcess }}</th>
          <th rowspan="2">昨日PM2.5减排量 {{ daily.Pm25 }}KG</th>
          <th rowspan="2">昨日PM10减排量 {{ daily.Pm10 }}KG</th>
          <th rowspan="2">昨日油烟减排量 {{ daily.RedEmissions }}KG</th>
          <th rowspan="2">昨日VOCS减排量 {{ daily.RedVocs }}KG</th>
        </tr>
        <tr>
          <th>占比 {{ Dtotal === 0 ? 0 : ((daily.Dnormal + daily.Dexcess) / Dtotal * 100).toFixed(2) + '%' }}</th>
          <th>占比 {{ Dtotal === 0 ? 0 : ((Dtotal - daily.Dnormal - daily.Dexcess) / Dtotal * 100).toFixed(2) + '%' }}
          </th>
          <th>占比 {{ Dtotal === 0 ? 0 : (daily.Dexcess / Dtotal * 100).toFixed(2) + '%' }}</th>
        </tr>
        </thead>
      </table>

            <baidu-map
              class="bm-view"
              ak="770b4c07458f53896ff0abd948755e20"
              :scroll-wheel-zoom="true"
              :center="mapCenter"
              :zoom="mapZoom"
              :map-click="false"
            >
              <DashboardMarker
                v-for="(item, i) in data"
                :key="i" :marker-data="item"
                @click.native.stop="handlerWindowOpen(item)"
              />
              <bm-info-window
                class="window_info_box"
                :position="{lng: infoWindowOpt.data.Lng, lat:infoWindowOpt.data.Lat}"
                :show="infoWindowOpt.show"
              >
                <p>
                  店铺名称:
                  <span>{{ infoWindowOpt.data.CustomerName }}</span>
                </p>
                <p>
                  油烟健康码:
                  <span class="iconfont" :class="infoWindowOpt.data.HealthCodeColor">&#xe704;</span>
                </p>
                <p>
                  联系电话:
                  <span>{{ infoWindowOpt.data.CustomerMobile }}</span>
                </p>
                <h5>监测点信息</h5>
                <p>
                  监测点名称:
                  <span>{{ infoWindowOpt.data.LocaleName }}</span>
                </p>
                <p>
                  菜系:
                  <span>{{ infoWindowOpt.data.CuisineName }}</span>
                </p>
                <p>
                  地址:
                  <span>{{ infoWindowOpt.data.Addr }}</span>
                </p>
                <!-- <div class="bg-purple"><span style="color:#909399">地址: </span><span>{{getSinglePname(e.Owner)+"/"+e.Name+":"+e.Addr}}</span></div> -->
                <template v-if="infoWindowOpt.data.MN">
                  <!-- <p>组织结构代码: <span>{{getSinglePname(e.Owner)+"/"+e.Name+":"+e.Addr}}</span></p> -->
                  <div class="bg-purple">
                    <span style="color:#909399">{{ infoWindowOpt.data.Typ === 2 ? '监测' : '监控' }}设备:</span>
                    <span>{{ infoWindowOpt.data.MN.substring(infoWindowOpt.data.MN.length - 8) }}</span>
                  </div>
                  <el-button
                    size="mini"
                    @click="handlePdfRecord(infoWindowOpt.data)"
                    type="text"
                    icon="el-icon-document"
                  >一点一档
                  </el-button>
                  <el-button
                    size="mini"
                    @click="handleShowIntime(infoWindowOpt.data.MN,infoWindowOpt.data.Typ)"
                    type="text"
                    icon="el-icon-notebook-2"
                  >1分钟数据列表
                  </el-button>
                  <el-button
                    v-if="infoWindowOpt.data.Typ===2"
                    size="mini"
                    @click="handleShowTenMin(infoWindowOpt.data.MN,infoWindowOpt.data.Typ, 0)"
                    type="text"
                    icon="el-icon-s-data"
                  >浓度曲线
                  </el-button>
                  <el-button
                    v-if="infoWindowOpt.data.Typ===2"
                    size="mini"
                    @click="handleShowTenMin(infoWindowOpt.data.MN,infoWindowOpt.data.Typ, 1)"
                    type="text"
                    icon="el-icon-s-data"
                  >历史数据
                  </el-button>
                </template>
              </bm-info-window>
            </baidu-map>
      <el-dialog :visible.sync="showDlg" @close="handlerClose" width="85%">
        <!-- <figure style="background-color: rgb(175, 173, 173)"></figure> -->
        <el-form :inline="true" size="mini" v-if="device.i === 0">
          <!-- <el-form-item>
            <el-select v-model="concentrationType" placeholder="请选择">
              <el-option :value="1" label="按十分钟"></el-option>
              <el-option :value="2" label="按天"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <!-- 按十分钟 -->
            <el-date-picker
              v-if="concentrationType === 1"
              @change="handleDateSel"
              v-model="selDate"
              type="date"
              value-format="timestamp"
              placeholder="日期选择"
            />

            <!-- 按天 -->
            <el-date-picker
              v-else-if="concentrationType === 2"
              type="daterange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-download" @click="handleExport">导出</el-button>
          </el-form-item>
        </el-form>
        <template v-if="showTyp">
          <!-- <span slot="title"><strong>10min浓度曲线</strong></span> -->
          <span slot="title">
            <strong>{{ device.i === 0 ? '浓度曲线' : '30天历史数据' }}</strong>
          </span>
          <div class="chart-line">
            <chart :options="tenMinOption" autoresize/>
          </div>
        </template>
        <template v-else>
          <span slot="title">
            <strong>每分钟采集数据</strong>
          </span>
          <el-table
            :data="intimeData.content"
            size="mini"
            border
            highlight-current-row
            v-loading="loading"
            :max-height="clientHeight"
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" align="center" width="55"/>
            <el-table-column
              prop="acquit_at"
              :formatter="dateFormat"
              label="采集时间"
              width="142"
              align="center"
              header-align="center"
            />
            <el-table-column
              :formatter="fanFilterFormatter"
              label="风机状态"
              align="center"
              header-align="center"
            />
            <el-table-column
              :formatter="fanFilterFormatter"
              label="净化器状态"
              align="center"
              header-align="center"
            />
            <template v-if="device.Typ === 2">
              <el-table-column
                prop="emissions_conc"
                label="油烟浓度(mg/m³)"
                align="center"
                header-align="center"
              />
              <el-table-column
                prop="granule_conc"
                label="颗粒物浓度(mg/m³)"
                align="center"
                header-align="center"
              />
              <el-table-column
                prop="hydrocarbon_conc"
                label="非甲烷总烃浓度(mg/m³)"
                align="center"
                header-align="center"
              />
              <!--
              <el-table-column prop='velocity' label='流速' align='center' header-align='center' />
              <el-table-column prop='temperature' label='温度' align='center' header-align='center' />
              <el-table-column prop='moisture' label='湿度' align='center' header-align='center' />-->
            </template>
            <template v-else>
              <el-table-column prop="poweroff" label="电源关闭指令" align="center" header-align="center"/>
              <!-- <el-table-column prop='Flag' label='设备状态' align='center' header-align='center' /> -->
            </template>
          </el-table>
          <el-pagination
            small
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="filter.page"
            :page-sizes="filter.pageSizes"
            layout="total, sizes, prev, pager, next, jumper"
            :total="intimeData.total"
            style="display:inline-block;margin-left:15px"
          />
        </template>
      </el-dialog>
    </div>
    <el-dialog
      :visible.sync="isShowNotificationDialog"
    >
      <notification7Dialog
        :device="device"
      ></notification7Dialog>
    </el-dialog>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import {
  ifNull,
  dateFormater,
  loadData,
  export_json,
  join,
  getUserInfo,
  getSinglePname,
  base64ToBlob, ownerJoin
} from '@/util/index'
import conf from '@/config'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue' // 局部注册
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
import BmInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow.vue'
// import BmNavigation from 'vue-baidu-map/components/controls/Navigation.vue'
import _1 from '@/asset/img/mapOnline.png'
import _3 from '@/asset/img/mapOffline.png'
import _2 from '@/asset/img/mapAlert.png'
import _4 from '@/asset/img/mapClosed.png'
import _5 from '@/asset/img/locale.png'
import _6 from '@/asset/img/mapPreAlert.png'
import DashboardMarker from '@/component/DashboardOverlay'

import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/legend'
import notification7Dialog from '@/component/Notification7Dialog'

export default {
  components: {
    BaiduMap,
    // BmMarker,
    BmInfoWindow,
    notification7Dialog,
    chart: ECharts,
    DashboardMarker
  },
  data() {
    return {
      infoWindowOpt: {
        show: false,
        data: {
          Lng: 0,
          Lat: 0
        }
      },
      concentrationType: 1, // 浓度类型：1按十分钟；2 按天
      pickerOptions: {
        // 查看浓度：按天
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      quickJumpMenus: [
        {
          type: 1,
          color: 'red',
          text: '超标报警',
          page: '/AlarmMessage?type=ExceedStandard'
        },
        {
          type: 2,
          color: 'yellow',
          text: '异常离线',
          page: '/AlarmMessage?type=AbnormalOffline'
        }
        // {
        //   type: 3,
        //   color: "yellow",
        //   text: "公众投诉",
        //   page: "/PublicParticipation",
        // },
      ],
      selDate: null,
      selOwner: null,
      selArea: null,
      data: [],
      now: null,
      Mtotal: 0,
      Dtotal: 0,
      LocaleNum: null,
      param: {
        locales: null
      },
      userInfo: null,
      loading: true,
      mapLoading: false,
      daily: {},
      mapCenter: {lng: 120.306305, lat: 30.424877},
      mapZoom: 14,
      showDlg: false,
      device: {},
      showTyp: null,
      exportData: null,
      linkStatus: null,
      intimeData: {},
      EmissionsSill: null,
      GranuleSill: null,
      HydrocarbonSill: null,
      isShowNotificationDialog: false,
      tenMinOption: {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['油烟浓度', '颗粒物浓度', '非甲烷总烃浓度']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          boundaryGap: true,
          axisLabel: {
            interval: 17
          }
        },
        yAxis: {
          type: 'value',
          // min: 5,
          // data : [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4,5],
          name: '平均浓度mg/m³'
        },
        series: [
          {
            name: '油烟浓度',
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: '#00A1FF',
                lineStyle: {
                  type: 'solid',
                  color: '#00A1FF'
                }
              }
            },
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#00A1FF'
                  },
                  label: {
                    position: 'start',
                    formatter: '{c} '
                  }
                }
              },
              data: [
                {
                  name: '阈值',
                  yAxis: 1
                }
              ]
            }
          },
          {
            name: '颗粒物浓度',
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: '#F5B049',
                lineStyle: {
                  type: 'solid',
                  color: '#F5B049'
                }
              }
            },
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#F5B049'
                  },
                  label: {
                    position: 'start',
                    formatter: '{c} '
                  }
                }
              },
              data: [
                {
                  name: '阈值',
                  yAxis: 1
                }
              ]
            }
          },
          {
            name: '非甲烷总烃浓度',
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: '#009999',
                lineStyle: {
                  type: 'solid',
                  color: '#009999'
                }
              }
            },
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#009999'
                  },
                  label: {
                    position: 'start',
                    formatter: '{c} '
                  }
                }
              },
              data: [
                {
                  name: '阈值',
                  yAxis: 1
                }
              ]
            }
          }
        ]
      },
      filter: {
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      }
    }
  },
  computed: {
    ...mapState(['areaTree', 'customerTree', 'clientHeight', 'locale']),
    ...mapState({
      orgProps: (state) =>
        Object.assign({}, state.props, {label: 'Org', checkStrictly: true}),
      areaProps: (state) =>
        Object.assign({}, state.props, {checkStrictly: true})
    })
  },
  mounted() {
    this.now = Date.parse(new Date()) / 1000
    this.userInfo = getUserInfo().Info
    loadData(this, () => {
      this.getData()
    })
  },
  methods: {
    getData() {
      // this.mapLoading = this.$loading({
      //   lock: true,
      //   text: '数据加载中，请稍候',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.5)'
      // })
      // if (!this.mapLoading) {
      //   this.mapLoading = true;
      // }
      let locales = this.locale
      if (this.selOwner) {
        const owner = `${ownerJoin(this.selOwner, this.userInfo)}`
        locales = this.locale.filter((v, _) => v.Owner.indexOf(owner) !== -1)
      }
      if (this.selArea) {
        const area = join(this.selArea)
        if (area !== '/1/') {
          locales = locales.filter((v, _) => v.AreaId.indexOf(area) === 0)
        }
      }
      if (locales.length === this.locale.length) {
        this.param.locales = null
      } else {
        this.param.locales = []
        locales.forEach((e) => {
          this.param.locales.push(e.Id)
        })
      }
      this.Mtotal = 0
      this.Dtotal = 0
      this.$post('admin/listMapDevice', this.param).then((res) => {
        this.daily = res.Daily
        this.data = res.Device
        console.log(this.data.length)
        res.Device.forEach((e) => {
          if (e.Typ === 1) {
            this.Mtotal += 1
          } else {
            this.Dtotal += 1
          }
        })
        this.LocaleNum = res.LocaleNum
        if (this.data.length !== 0) {
          this.mapCenter = {lng: this.data[0].Lng, lat: this.data[0].Lat}
        }
        this.mapLoading = false
      })
    },
    getIcon(status) {
      switch (status) {
        case 'NORMAL':
          return _1
        case 'EXCESS':
          return _2
        case 'DOWN':
          return _3
        case 'OFF':
          return _4
        case 'ALARM':
          return _6
        default:
          return _5
      }
    },
    getSinglePname(pid) {
      return getSinglePname(this.customerTree, pid, 'Org')
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], true)
    },
    fanFilterFormatter(r) {
      if (this.linkStatus === 1) {
        return '开'
      }
      return '-'
    },
    handleExport() {
      if (this.showTyp) {
        export_json(
          dateFormater(this.selDate / 1000, false) + '-' + this.mn,
          this.exportData,
          {
            采集时间: '采集时间',
            油烟平均浓度: '油烟平均浓度',
            颗粒物平均浓度: '颗粒物平均浓度',
            非甲烷总烃平均浓度: '非甲烷总烃平均浓度'
          }
        )
      } else {
        this.$download('admin/exportIntimeData', {
          mn: this.device.MN,
          typ: this.device.Typ,
          at: this.selDate / 1000
        })
      }
    },
    handleDateSel(v) {
      if (v !== null) {
        if (this.showTyp) {
          this.getTenMinData(0)
        } else {
          this.getIntimeData()
        }
      }
    },
    selsChange: function (sels) {
      this.sels = sels
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getIntimeData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getIntimeData()
    },
    getIntimeData() {
      this.loading = true
      this.$post('admin/listIntimeData', {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        mn: this.device.MN,
        typ: this.device.Typ,
        at: this.selDate / 1000
      }).then((res) => {
        this.loading = false
        this.intimeData = res
        if (res.total !== 0 && this.selDate === null) {
          this.selDate = new Date(
            new Date(res.content[0]['acquit_at'] * 1000).setHours(0, 0, 0, 0)
          ).getTime()
        }
      })
    },
    getTenMinData(i) {
      this.loading = true
      const api = ['admin/listTenMinData', 'admin/listTenMinDataLastDays']
      const days = i === 1 ? 30 : 1
      const interval = i === 1 ? 431 : 17
      const bl = i !== 1
      const barType = i === 1 ? 'bar' : 'line'
      this.$post(api[i], {
        mn: this.device.MN,
        at: this.selDate / 1000,
        LastDays: i === 1 ? 30 : undefined
      }).then((res) => {
        this.loading = false
        this.exportData = []
        this.tenMinOption.series[0]['data'] = []
        this.tenMinOption.series[0]['type'] = barType
        this.tenMinOption.series[0]['markLine']['data'][0]['yAxis'] = this.EmissionsSill
        this.tenMinOption.xAxis['data'] = []
        this.tenMinOption.xAxis['axisLabel']['interval'] = interval
        this.tenMinOption.series[1]['data'] = []
        this.tenMinOption.series[1]['type'] = barType
        this.tenMinOption.series[1]['markLine']['data'][0]['yAxis'] = this.GranuleSill
        this.tenMinOption.series[2]['data'] = []
        this.tenMinOption.series[2]['type'] = barType
        this.tenMinOption.series[2]['markLine']['data'][0]['yAxis'] = this.HydrocarbonSill
        if (res && res.length !== 0) {
          if (this.selDate === null) {
            this.selDate = new Date(res[0]['acquit_at'] * 600 * 1000).setHours(0, 0, 0, 0)
          }
          const endTime = Math.floor(this.selDate / 600000) + 143 * days
          for (var i = Math.floor(this.selDate / 600000); i <= endTime; i++) {
            let obj = {
              emissions_conc: 0,
              granule_conc: 0,
              hydrocarbon_conc: 0,
              counter: 1
            }
            res.forEach(e => {
              if (e.acquit_at === i) {
                obj = e
                this.exportData.push({
                  采集时间: dateFormater(e.acquit_at * 600, true, bl),
                  油烟平均浓度: (e.emissions_conc).toFixed(2),
                  颗粒物平均浓度: (e.granule_conc).toFixed(2),
                  非甲烷总烃平均浓度: (e.hydrocarbon_conc).toFixed(2)
                })
              }
            })
            this.tenMinOption.series[0]['data'].push(
              (obj.emissions_conc).toFixed(2)
            )
            this.tenMinOption.series[1]['data'].push(
              (obj.granule_conc).toFixed(2)
            )
            this.tenMinOption.series[2]['data'].push(
              (obj.hydrocarbon_conc).toFixed(2)
            )
            this.tenMinOption.xAxis['data'].push(dateFormater(i * 600, true, bl))
          }
          this.$forceUpdate()
        }
      })
    },
    getLast30Data() {
      this.loading = true
      let now = new Date()
      now = now.getTime()
      const last = now - 30 * 24 * 3600 * 1000
      const par = {
        mn: this.device.MN,
        AcquitAtBegin: parseInt(last / 1000),
        AcquitAtEnd: parseInt(now / 1000)
      }
      this.$post('admin/queryDataDetectorDaily', par).then((res) => {
        this.loading = false
        this.exportData = []
        this.tenMinOption.series[0]['data'] = []
        this.tenMinOption.series[0]['type'] = 'bar'
        this.tenMinOption.series[0]['markLine']['data'][0]['yAxis'] = this.EmissionsSill
        this.tenMinOption.xAxis['data'] = []
        this.tenMinOption.xAxis['axisLabel']['interval'] = 1
        this.tenMinOption.series[1]['data'] = []
        this.tenMinOption.series[1]['type'] = 'bar'
        this.tenMinOption.series[1]['markLine']['data'][0]['yAxis'] = this.GranuleSill
        this.tenMinOption.series[2]['data'] = []
        this.tenMinOption.series[2]['type'] = 'bar'
        this.tenMinOption.series[2]['markLine']['data'][0]['yAxis'] = this.HydrocarbonSill
        if (res && res.length !== 0) {
          for (let i = res.length - 1; i >= 0; i--) {
            this.exportData.push({
              采集时间: res[i].AcquitDate.substr(5, 5),
              油烟平均浓度: res[i].Emissions.toFixed(2),
              颗粒物平均浓度: res[i].Granule.toFixed(2),
              非甲烷总烃平均浓度: res[i].Hydrocarbon.toFixed(2)
            })
            this.tenMinOption.series[0]['data'].push(
              (res[i].Emissions).toFixed(2)
            )
            this.tenMinOption.series[1]['data'].push(
              (res[i].Granule).toFixed(2)
            )
            this.tenMinOption.series[2]['data'].push(
              (res[i].Hydrocarbon).toFixed(2)
            )
            this.tenMinOption.xAxis['data'].push(res[i].AcquitDate.substr(5, 5))
          }
          this.$forceUpdate()
        }
      })
    },
    handleShowIntime(mn, typ) {
      this.device = {MN: mn, Typ: typ}
      this.showTyp = false
      this.showDlg = true
      this.getIntimeData()
    },
    handleShowTenMin(mn, typ, i) {
      this.device = {MN: mn, Typ: typ, i: i}
      this.showTyp = true
      this.showDlg = true
      if (i === 0) {
        this.getTenMinData(0)
      } else {
        this.getLast30Data()
      }
    },
    handlerWindowOpen(data) {
      this.infoWindowOpt.data = {...data}
      this.infoWindowOpt.show = true
      this.EmissionsSill = data['EmissionsSill']
      this.GranuleSill = data['GranuleSill']
      this.HydrocarbonSill = data['HydrocarbonSill']
      this.linkStatus = data['LinkStatus']
    },
    handlerWindowClose(i) {
      this.infoWindowOpt.show = false
    },
    handlerClose() {
      this.selDate = null
      // this.getData()
    },
    handlePdfRecord(data) {
      this.device = data
      this.isShowNotificationDialog = true
    }
  }
}
</script>
<style lang="scss" scoped>
// 快速跳转 css
.quick_jump_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    color: #333333;
    font-size: 12px;
    padding: 0 10px;

    i {
      font-size: 18px;
    }

    &:hover {
      color: #409eff;
    }
  }
}


.bm-view {
  width: 100%;
  height: 70vh;

  .BMap_bubble_content div {
    padding-top: 8px;
  }

  .bg-purple {
    // padding: 4px;
    // border-radius: 4px;
    font-size: 13px;
    min-width: 320px;
    // background: #E1EDF2;
  }
}

.window_info_box {
  p {
    margin: 0;
    font-size: 12px;
    color: #999999;

    span {
      color: #333333;
    }
  }

  h5 {
    font-size: 12px;
    // margin: 8px 0 5px;
    margin: 5px 0 0;
  }
}

.device-info {
  display: table;
}
</style>
